<template>
  <div class="external-footer">
    <div class="external-footer__left">
      <img src="/images/logo_golstats_horizontal_black.svg" alt="logo golstats" />
    </div>
    <template v-if="colorsConfig.show_logo && showLogos">
      <div class="external-footer__divider" />
      <BetLogo height="28px" width="auto" :url-logo="colorsConfig.secondary_logo" :url-link="colorsConfig.url_base" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExternalFooter',
  components: {
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  props: {
    showLogos: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('profileGeneral', ['colorsConfig']),
  },
};
</script>

<style lang="scss" scoped>
.external-footer {
  z-index: 1;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;

  &__divider {
    width: 1px;
    height: 0.75rem;
    border-right: 1px solid #979797;
    margin: 0 16px;
  }

  &__left,
  &__right {
    height: auto;
    //width: 15%;
    //min-width: max-content;
    img {
      height: 1.5rem;
      //margin: 0 15px;
    }
  }
}
</style>
